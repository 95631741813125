import { Component } from 'react';
import { connect } from 'react-redux';
import { SHr, SIcon, SLoad, SText, STheme, SThread, SView } from 'servisofts-component';
import dispositivo from '../../dispositivo';
import SSocket from 'servisofts-socket';
import Model from '../../../../../Model';
class SincronizarUsuario extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }


  discor(info) {
    let usuario = Model.usuario.Action.getUsuarioLog();

    let cliente = this.props.key_usuario;
    let _mensaje = `Cliente: ${cliente} - ${info}`;
    const url = "https://discord.com/api/webhooks/1323413166151172147/3fq20RQG7xfO3YOV6gms1vh_I-kwEE07o8T848Egf-0aWV_8x469r_gtx0ntCBnXu_hI";

    fetch(url, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        username: usuario.Nombres + " " + usuario.Apellidos,
        avatar_url: " " + SSocket.api.root + "usuario/" + usuario.key,
        content: _mensaje
      })
    })
      .then(response => {
        if (response.ok) {
          console.log("Mensaje enviado exitosamente");
        } else {
          console.error("Error en la respuesta del servidor", response.status, response.statusText);
        }
      })
      .catch(error => console.error("Error al enviar el mensaje: ", error.message));
  }

  getContent() {
    if (this.state.loading) return <SLoad />
    return <SText center col={"xs-12"} fontSize={18}>Guardar huella de usuario  <SIcon center name={"Reload"} fill={STheme.color.text} style={{ height: 16, width: 16 }} />
    </SText>

  }

  getTamaño() {
    const width = window.innerWidth;

    // Móviles (Android/iOS)
    if (width <= 467) return 'Mobile';

    // Tabletas
    if (width <= 1023 && width <= 768) return 'Tablet';

    // Escritorios
    return 'Desktop';
  }
  getMobileOperatingSystem() {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;

    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
      return "Windows Phone";
    }

    if (/android/i.test(userAgent)) {
      return "Android";
    }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return "iOS";
    }

    return "unknown";
  }

  render() {
    if (!this.props.key_usuario) return null;
    return (
      <SView onPress={() => {


        this.discor("Huella actualizada al server molinete");

        // console.log("holaaa sincronizar ")
        // const deviceType = this.getMobileOperatingSystem();
        // const deviceTamaño = this.getTamaño();
        // console.log("gita ", navigator.userAgentData.mobile);
        // console.log('Tipo de dispositivo:', deviceType, " tmañao: ", deviceTamaño);

        // ------------------------------------------------------------
        if (this.state.loading) return;
        dispositivo.Actions.sincronizarAll(this.props);
        new SThread(3000, "load", true).start(() => {
          this.setState({ loading: false })
        })
        this.setState({ loading: true })
        // ------------------------------------------------------------
        SSocket.send({
          servicio: "zkteco",
          component: "zkteco",
          type: "sincronizarUsuario",
          estado: "cargando",
          key_usuario: this.props.state.usuarioReducer.usuarioLog.key,
          data: {
            key_usuario: this.props.key_usuario,
            // key_sucursal: this.key_sucursal,
          }
        })
      }} center style={{
        borderWidth: 1,
        borderRadius: 8,
        height: 60,
        width: "100%",
        borderColor: "#ddd",
        backgroundColor: STheme.color.card
      }}>
        {this.getContent()}
      </SView>
    );
  }
}
const initStates = (state) => {
  return { state }
};
export default connect(initStates)(SincronizarUsuario);