import React, { Component } from 'react';
import { SIcon, SLoad, SPopup, SText, STheme, SView } from 'servisofts-component';
import Parent from ".."
import Model from '../../../../../Model';
import SSocket from 'servisofts-socket';

class BtnTestConnection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      exito: false
    };
  }

  discor(info) {
    let usuario = Model.usuario.Action.getUsuarioLog();
    let _mensaje = `${info}\nUsuario: ${usuario.Nombres} ${usuario.Apellidos}\nSucursal: ${this.props.sucursal}`;
    const url = "https://discord.com/api/webhooks/1321199346339024926/rsSy3FBsDU0sr-0Nw9uj6sZxR8iFUGdG1n1dvybRtL0Fkl8o0iWvGN5lsJZ52iYFtB7W";
    fetch(url, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        username: usuario.Nombres + " " + usuario.Apellidos,
        avatar_url: " " + SSocket.api.root + "usuario/" + usuario.key,
        content: _mensaje
      })
    })
      .then(response => {
        if (response.ok) {
          console.log("Mensaje enviado exitosamente");
        } else {
          console.error("Error en la respuesta del servidor", response.status, response.statusText);
        }
      })
      .catch(error => console.error("Error al enviar el mensaje: ", error.message));
  }

  getContent = () => {
    if (this.state.loading) return <SLoad />
    return <SView col={"xs-12"} height center row>
      <SIcon name={"Wifi"} fill={this.state.exito ? "#0f0" : STheme.color.lightGray} height={18} width={18} />
      <SView width={8} />
      <SText color={this.state.exito ? "#0f0" : STheme.color.lightGray}>{this.state.exito ? "CONNECTION SUCCESS" : "Test Connection molinete"}</SText>
    </SView>
  }

  evento = () => {
    if (this.state.loading) return;
    this.setState({ loading: true });
    Parent.Actions.testConnection(this.props.dispositivo?.key, this.props.dispositivo?.key_punto_venta).then((resp) => {
      this.setState({ loading: false });
      if (resp.estado == "exito") {
        this.setState({ exito: true });
        this.setState({ loSading: false });
        alert("✅ ¡ÉXITO EN LA CONEXIÓN! " + this.props.sucursal)
        this.discor("TEST CONEXIÓN ÉXITO ✅! ")
      }
    }).catch((e) => {
      this.setState({ exito: false });
      this.setState({ loading: false });
      alert("❌ ¡ERROR EN LA CONEXIÓN! 🚫\nPor favor, reinicie el programa del molinete 🔄");
      this.discor("TEST CONEXIÓN FALLIDA ❌!")
    })
  }

  render() {
    return (
      <SView col={"xs-12"} center height={30}
        onPress={() => {
          this.evento();
        }} >
        {this.getContent()}
      </SView>
    );
  }
}

export default (BtnTestConnection);