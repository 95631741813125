import { Component } from 'react';
import { View } from 'react-native';
import { connect } from 'react-redux';
import { SButtom, SInput, SMath, SNavigation, SPopup, SPopupOpen, SText, STheme, SView } from 'servisofts-component';
import SSocket from 'servisofts-socket';
import PopupCerrar from './PopupCerrar';
import PopupConfirmarCierre from './PopupConfirmarCierre';
import Model from '../../../../../Model';
import Sucursal from '../../../../Sucursal';

class EstadoCaja extends Component {
  constructor(props) {
    super(props);
    this.state = {
      montoDefault: "200.00"
    };
  }
  componentDidMount() {
    this.getActiva();
  }
  getActiva() {
    var obj = {
      component: "caja",
      type: "getActiva",
      estado: "cargando",
      key_usuario: this.props.state.usuarioReducer.usuarioLog.key
    }
    SSocket.send(obj, true);
  }

  getBtn() {
    if (!this.props.sucursal) {
      return <SText style={{ color: STheme.color.darkGray, fontSize: 10 }}>Seleccione su sucursal para continuar</SText>
    }


    // var numeroMultiplicado = total * 100;
    // // Redondear al entero más cercano
    // var numeroRedondeado = Math.round(numeroMultiplicado);
    // // Dividir nuevamente por 100 para mover dos lugares decimales a la izquierda
    // let aux = numeroRedondeado / 100;
    // console.log("da ",aux)
    console.log("dadd ", this.montoCaja)

    return <SButtom props={{ type: "danger", variant: "confirm" }}
      onPress={() => {
        // var value = this.inputr.getValue();
        // if (!value) {
        // value = this.state.montoDefault;
        // }
        var obj = {
          component: "caja",
          type: "registro",
          estado: "cargando",
          key_usuario: this.props.state.usuarioReducer.usuarioLog.key,
          data: {
            key_usuario: this.props.state.usuarioReducer.usuarioLog.key,
            monto: this.montoCaja,
            key_sucursal: this.props.sucursal.key
          }
        }
        this.discorAperturaCaja("apertura caja " + this.montoCaja + " bs")

        SSocket.send(obj, true);
      }}>Abrir</SButtom>
  }
  getMontoCajaAntigua() {
    if (!this.props.sucursal) {
      return <View />
    }
    var data = this.props.state.sucursalReducer.monto[this.props.sucursal.key];
    if (!data) {
      if (this.props.state.sucursalReducer.estado == "cargando") {
        return <View />
      }
      var obj = {
        component: "sucursal",
        type: "getMontoCaja",
        estado: "cargando",
        key_usuario: this.props.state.usuarioReducer.usuarioLog.key,
        key_sucursal: this.props.sucursal.key
      }
      SSocket.send(obj, true);
      return <View />
    }
    if (!data.monto) {
      data.monto = 0;
    }
    this.montoCaja = data.monto;


    // if (this.montoCaja % 1 != 0) this.montoCaja = this.montoCaja.toFixed(2);
    // return <SText>{JSON.stringify(data,"\s","\t")}</SText>
    return <SInput {...{ col: "xs-12", customStyle: "calistenia", type: "money", label: "Monto en caja", variant: "small", }} value={SMath.formatMoney(data.monto) + ""} editable={false} />
  }

  discorAperturaCaja(info) {
    let usuario = Model.usuario.Action.getUsuarioLog();
    let sucursal = Sucursal.Actions.getByKey(this.props?.sucursal?.key, this.props);

    let _mensaje = `${info}\nUsuario: ${usuario.Nombres} ${usuario.Apellidos}\nSucursal: ${sucursal.descripcion}`;

    const url = "https://discord.com/api/webhooks/1321199017820033115/65rTBtBh23v7cWS_1gr1D_GtFkY7_ia34NQ_uV4sK2axfcpNLXTRtU-DzmTfKdBcaXjG";

    fetch(url, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        username: usuario.Nombres + " " + usuario.Apellidos,
        avatar_url: " " + SSocket.api.root + "usuario/" + usuario.key,
        content: _mensaje
      })
    })
      .then(response => {
        if (response.ok) {
          console.log("Mensaje enviado exitosamente");
        } else {
          console.error("Error en la respuesta del servidor", response.status, response.statusText);
        }
      })
      .catch(error => console.error("Error al enviar el mensaje: ", error.message));
  }
  discorCierreCaja(info) {
    let usuario = Model.usuario.Action.getUsuarioLog();
    let sucursal = Sucursal.Actions.getByKey(this.props?.sucursal?.key, this.props);

    let _mensaje = `${info}\nUsuario: ${usuario.Nombres} ${usuario.Apellidos}\nSucursal: ${sucursal.descripcion}`;

    const url = "https://discord.com/api/webhooks/1321949910131802215/d6I-V1WtdMTLV0wHsygGhq8pmqDixytcIcNlbZ1OdJ1E3_112dFFeeQUzqlEiq_HWfgK";

    fetch(url, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        username: usuario.Nombres + " " + usuario.Apellidos,
        avatar_url: " " + SSocket.api.root + "usuario/" + usuario.key,
        content: _mensaje
      })
    })
      .then(response => {
        if (response.ok) {
          console.log("Mensaje enviado exitosamente");
        } else {
          console.error("Error en la respuesta del servidor", response.status, response.statusText);
        }
      })
      .catch(error => console.error("Error al enviar el mensaje: ", error.message));
  }


  apertura() {
    if (this.activa) {
      // alert("activa apert")
      return <View />
    }
    return (
      <SView col={"xs-10"} center   >
        {this.getMontoCajaAntigua()}
        <SView col={"xs-12"} style={{ height: 4, }}></SView>
        {/* <SInput ref={(ref) => { this.inputr = ref }} props={{ col: "xs-12", customStyle: "calistenia", type: "money", label: "Monto de apertura", variant: "small" }} placeholder={this.state.montoDefault} />, */}
        {this.getInfo()}
        <SView col={"xs-12"} style={{ height: 16, }}></SView>
        {this.getBtn()}
        <SView col={"xs-12"} style={{ height: 20, }}></SView>
      </SView>
    );
  }
  getBtnClose() {
    if (!this.activa) {
      return <View />
    }

    // console.log("puchi ", SMath.parseFloat(this.getMonto()))

    //aqui oculto boton por el numero total esta negativo
    if (SMath.parseFloat(this.getMonto()) < 0) {
      return <View />
    }



    return <SButtom props={{ type: "danger", variant: "default", }}
      style={{
        width: 100,
        height: 30,
      }}
      onPress={() => {
        // if (SMath.parseFloat(this.getMonto()) < 0) {
        //   alert("No puede cerrar, Monto negativo")
        //   return;
        // }
        SPopup.open({
          key: "ConfirmarCierreCaja",
          content: < PopupConfirmarCierre data={this.activa} onSelect={(select) => {
            if (select == "banco") {
              SPopupOpen({
                key: "cerrarCaja",
                content: <PopupCerrar data={this.activa} total={this.total} navigation={this.props.navigation} />
              })
            } else {
              var total = this.getMontoumber() ?? 0
              var obj = {
                component: "caja",
                type: "cierre",
                estado: "cargando",
                key_usuario: this.props.state.usuarioReducer.usuarioLog.key,
                data: {
                  key_caja: this.activa.key,
                  monto_salvar: parseFloat(total) ?? 0,
                  key_usuario: this.props.state.usuarioReducer.usuarioLog.key,
                }
              }

              this.discorCierreCaja("cierre caja " + parseFloat(total) ?? 0 + " bs")
              SSocket.send(obj);
              SNavigation.navigate("ReciboCaja", { key: this.activa.key });
            }
            SPopup.close("ConfirmarCierreCaja");
          }} />
        })


      }
      }> cerrar la caja</SButtom >
  }
  getMonto() {
    if (!this.activa) {
      return 0
    }
    var reducer = this.props.state.cajaMovimientoReducer;
    var data = reducer.data[this.activa.key];
    if (!data) {
      return 0;
    }
    var total = 0;
    var keys = Object.keys(data);
    for (var i = 0; i < keys.length; i++) {
      var obj = data[keys[i]];
      if (obj.estado == 3) continue;
      // if (obj.key_tipo_pago == 4) continue;
      total += obj.monto;
    }
    if (total % 1 != 0) total = total.toFixed(2);
    return SMath.formatMoney(total);
  }
  getMontoumber() {
    if (!this.activa) {
      return 0
    }
    var reducer = this.props.state.cajaMovimientoReducer;
    var data = reducer.data[this.activa.key];
    if (!data) {
      return 0;
    }
    var total = 0;
    var keys = Object.keys(data);
    for (var i = 0; i < keys.length; i++) {
      var obj = data[keys[i]];
      if (obj.estado == 3) continue;
      // if (obj.key_tipo_pago == 4) continue;
      total += obj.monto;
    }

    var numeroMultiplicado = total * 100;
    // Redondear al entero más cercano
    var numeroRedondeado = Math.round(numeroMultiplicado);
    // Dividir nuevamente por 100 para mover dos lugares decimales a la izquierda
    let aux = numeroRedondeado / 100;
    console.log("da ", aux)
    return aux;

    // return total;
    // return total;
    // if (total % 1 != 0) total = total.toFixed(2);
    // return SMath.formatMoney(total);
  }
  getMontoEnCaja() {
    if (!this.activa) {
      return <View></View>
    }
    return <SView style={{
      width: 150,
      height: 50,
      backgroundColor: STheme.color.card,
      borderRadius: 8,
    }} center>
      <SView row >
        <SText style={{ fontSize: 10, }}> Bs. </SText>
        <SText style={{ fontSize: 18, }}>{this.getMonto()}</SText>
      </SView>
    </SView>
  }
  cierre() {
    if (!this.activa) {
      return <View />
    }

    return (
      <SView center col={"xs-10"} style={{
        flex: 1,
        justifyContent: "space-evenly"
      }}>

        <SView style={{
          width: "100%",
        }} center>
          <SText style={{ color: STheme.color.darkGray, fontSize: 10, }}>{"Monto actual en caja."}</SText>
        </SView>
        {this.getMontoEnCaja()}
        <SView col={"xs-12"} style={{ height: 50, }}></SView>
        {this.getBtnClose()}
        <SView col={"xs-12"} style={{ height: 50, }}></SView>
        {/* <SButtom type={"danger"}>MOLINETE</SButtom> */}
        {/* <SView col={"xs-12"} style={{ height: 50, }}></SView> */}

      </SView>
    );
  }
  getInfo() {
    if (!this.props.sucursal) {
      return <View />
    }
    if (this.activa) {
      return <View />
    }
    return <SView style={{
      width: "100%",
    }} center>
      <SText style={{ color: STheme.color.lightGray, fontSize: 10, }}>{"El monto en caja, representa el monto salvado en el último cierre de caja de esta sucursal."}</SText>
    </SView>
  }

  setColor(numero) {
    if (SMath.parseFloat(this.getMonto()) < 0) return "red";
  }

  render() {
    this.activa = this.props.state.cajaReducer.usuario[this.props.state.usuarioReducer.usuarioLog.key];
    if (!this.props.sucursal) {
      if (this.activa) {
        if (this.props.setKeySucursal) {
          this.props.setKeySucursal(this.activa.key_sucursal, this.activa.key);
        }
      }
    }
    if (this.props.state.cajaReducer.estado == "exito" && this.props.state.cajaReducer.type == "registro") {
      this.props.state.cajaReducer.estado = "";
      this.props.state.sucursalReducer.monto = {};
    }



    return (
      <SView col="xs-11 md-6 xl-4" style={{
        backgroundColor: STheme.color.card,
        borderRadius: 8,
        marginTop: 8,
        minHeight: 50,
        borderWidth: 3,
        borderColor: this.setColor()

      }} center>
        {this.apertura()}
        {this.cierre()}
        <SText style={{
          width: 100,
          position: "absolute",
          right: 4,
          bottom: 4,
          fontSize: 10,
          color: STheme.color.darkGray
        }} center>Estado de caja:  {!this.activa ? "Cerrada" : "Activa"}</SText>
      </SView>
    );
  }
}
const initStates = (state) => {
  return { state }
};
export default connect(initStates)(EstadoCaja);