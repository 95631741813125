import { Component } from 'react';
import { connect } from 'react-redux';
import { SList2, SLoad, SPage, SText, SView } from 'servisofts-component';
import Parent from "..";
import DeviceItem from '../Components/DeviceItem';
class Lista extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  mensajeLoad(_texto) {
    return <SView col={"xs-12"} flex center  >
      <SView col={"xs-12"} row >
        <SView flex style={{ alignItems: "flex-end" }}>
          <SText><SLoad />       </SText>
        </SView>
        <SView flex style={{ alignItems: "flex-start" }}>
          <SText color='cyan'>Cargando server {_texto}..</SText>;
        </SView>
      </SView>
    </SView>
  }

  getLista() {
    var data = Parent.Actions.getAll(this.props);
    if (!data) return this.mensajeLoad("zkteco")
    return <SList2
      horizontal
      space={0}
      data={data}
      render={obj => <SView col={"xs-12 sm-6 md-4 lg-3"} style={{ padding: 8 }}>
        <DeviceItem obj={obj} />
      </SView>}
    />
  }
  render() {
    return (
      <SPage title={'Lista de dispositivos - Molinetes'} center   >
        <SView col={"xs-12"} flex >
          {this.getLista()}
        </SView>
      </SPage>
    );
  }
}
const initStates = (state) => {
  return { state }
};
export default connect(initStates)(Lista);