import { Component } from "react";
import { connect } from "react-redux";
import { SDate, SIcon, SLoad, SNavigation, SPage, SPopup, STable2, SText, STheme, SView } from "servisofts-component";
import SSocket from "servisofts-socket";
// import Botoncito from "../../Components/Botoncito";
// import Model from "../../Model";
// import sucursal_usuario from "../sucursal_usuario";

class ReporteZkteco extends Component {
  constructor(props) {
    super(props);
    this.key_dispositivo = SNavigation.getParam("key_dispositivo");
    this.state = {
      title: "Export Base Datos",
      func: "_romeo2",
      parametros: {
        inicio: "2015-01-01",
        fin: "2028-02-01",
      },
      ...this.state,
    };
  }

  componentDidMount() {
    this.getData();
  }

  mensajeLoad(_texto) {
    return <SView col={"xs-12"} flex center  >
      <SView col={"xs-12"} row >
        <SView flex style={{ alignItems: "flex-end" }}>
          <SText><SLoad />       </SText>
        </SView>
        <SView flex style={{ alignItems: "flex-start" }}>
          <SText color='green'>Cargando server {_texto}..</SText>;
        </SView>
      </SView>
    </SView>
  }

  getData() {
    this.setState({ loading: "cargando", data: null });
    SSocket.sendPromise({
      service: "zkteco",
      component: "dispositivo",
      type: "reportedatosmolinete",
      key_dispositivo: this.key_dispositivo,
      key_sucursal: "",
      key_usuario: "",
    }, 1000 * 60)
      .then((resp1) => {
        let keys = [...new Set(resp1.data.map(a => a.key_usuario).filter(key => key !== null))];
        SSocket.sendPromise({
          version: "2.0",
          service: "usuario",
          component: "usuario",
          type: "getAllKeys",
          keys: keys,
        }).then(resp => {
          console.log(resp)
          this.state.data = [];
          resp1.data.map(o => {
            o.usuario = resp.data[o.key_usuario]?.usuario;
          })
          this.setState({ data: resp1.data })
        }).catch(e2 => {
          console.error(e);
        })
        // this.setState({ loading: false, data: resp1.data });
      })
      .catch((e) => {
        this.setState({ loading: false, error: e });
      });
  }

  getTable() {
    var data = this.state.data;
    if (!data) return this.mensajeLoad("zkteco");
    return <STable2
      rowHeight={30}
      limit={100}
      header={[
        { key: "index", label: "#", width: 30 },

        {
          key: "usuario", label: "Usuario", width: 270,
          render: (usuario) => {
            return usuario?.Nombres + " " + usuario?.Apellidos
          },
        },
        { key: "Pin", label: "PIN", width: 50, },
        {
          key: "-huellas-cant", order: "desc", label: "Cant. Huellas molinete", width: 150,
          sumar: true,
          render: (arr) => {
            if (!arr) return 0;
            let cantidad = 0;
            return arr.huellas.length
          },
          component: (e) => <SView center>{!e ? <SText color="pink" bold>{e}</SText> : <SText color={STheme.color.text}>{e}</SText>}</ SView>
        },
        {
          key: "key_usuario-4", label: "Perfil", width: 90,
          component: (e) => <SView center
            style={{ borderWidth: 1, borderColor: "blue", borderRadius: 4, padding: 4 }}
            onPress={() => { SNavigation.navigate("Huellas", { key: e }); }}
          >{!e ? null : <SText color="yellow">Huella</SText>}</ SView>
        },
        {
          key: "-key_usuario-2", label: "Detalle", width: 130,
          component: (e) => <SView center style={{ borderWidth: 1, borderColor: "yellow", borderRadius: 4, padding: 2 }}
            onPress={() => {
              SPopup.confirm({
                title: "¿Está seguro de borrar la huella?",
                message: "USUARIO - " + e.usuario?.Nombres + " " + e.usuario?.Apellidos,
                onPress: () => {
                  SSocket.sendPromise({
                    service: "zkteco",
                    component: "dispositivo",
                    type: "eliminarPin",
                    key_dispositivo: this.key_dispositivo,
                    pin: e.Pin,
                    key_sucursal: "",
                    key_usuario: e.key_usuario,
                  }, 1000 * 60)
                    .then((resp) => {
                      alert("exito se elimino la huella del pin")
                    })
                    .catch((e) => {
                      alert("error")
                    });
                }
              });
            }}>{!e ? null : <SText color="red">Eliminar huella</SText>}</ SView>
        },
      ]}
      data={data}
    />
  }
  render() {
    return (
      <SPage title={"REPORTE molinetes zkteco"} disableScroll center>
        {this.getTable()}
      </SPage>
    );
  }
}

const initStates = (state) => {
  return { state };
};
export default connect(initStates)(ReporteZkteco);
