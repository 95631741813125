import { Component } from 'react';
import { connect } from 'react-redux';
import { SDate, SHr, SIcon, SLoad, SNavigation, SPopup, SText, STheme, SView } from 'servisofts-component';
import SSocket from 'servisofts-socket';
import Parent from "..";
import Model from '../../../../../Model';

class HuellasDeUsuario extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  onSubmit() {

  }

  discor(info) {
    // var data = Parent.Actions.getByKeyUsuario(this.props.key_usuario, this.props);
    // if (!data) return <SLoad />

    let usuario = Model.usuario.Action.getUsuarioLog();
    let cliente = this.props.key_usuario;

    // let sucursal = Sucursal.Actions.getByKey(this.props?.key_sucursal, this.props);

    // Formatear el mensaje para que sea más legible
    // let _mensaje = `${info}\nUsuario: ${usuario.Nombres} ${usuario.Apellidos}`;
    // let _mensaje = `${info}\nUsuario: ${usuario.Nombres} ${usuario.Apellidos}`;
    let _mensaje = `Cliente: ${cliente} - ${info}`;

    // console.log("s" + JSON.stringify(usuario))
    const url = "https://discord.com/api/webhooks/1323413166151172147/3fq20RQG7xfO3YOV6gms1vh_I-kwEE07o8T848Egf-0aWV_8x469r_gtx0ntCBnXu_hI";

    fetch(url, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        username: usuario.Nombres + " " + usuario.Apellidos,
        avatar_url: " " + SSocket.api.root + "usuario/" + usuario.key,
        content: _mensaje
      })
    })
      .then(response => {
        if (response.ok) {
          console.log("Mensaje enviado exitosamente");
        } else {
          console.error("Error en la respuesta del servidor", response.status, response.statusText);
        }
      })
      .catch(error => console.error("Error al enviar el mensaje: ", error.message));
  }

  template() {
    // console.log("feo ", obj)
    return <>
      <SView center border={"transparent"} style={{ position: "absolute", top: 5, right: 0, }}
        onPress={() => {
          SPopup.confirm({
            title: "Eliminar", message: "¿Esta seguro de eliminar huellas?", onPress: () => {
              // Parent.Actions.eliminar(this.props.key_usuario, this.props);
              alert("aun no esta aliminado")
            }
          })
        }}
      >
        <SIcon name="Cerrar" fill={"red"} width={8} />
      </SView>
    </>
  }

  getLista() {
    var data = Parent.Actions.getByKeyUsuario(this.props.key_usuario, this.props);
    if (!data) return <SLoad />
    var arr = Array.from({ length: 2 }, (x, i) => i + 1)
    return arr.map((obj) => {
      var huella = data.find((x) => x.codigo == obj);
      if (obj == 1) return;
      return <>
        <SView width={8} />
        <SView border={STheme.color.secondary + "22"} style={{
          width: 180, padding: 16, borderRadius: 6
        }} center onPress={() => {
          SNavigation.navigate("SucursalPage", {
            onSelect: (itm) => {
              this.setState({
                solicitud: {
                  ...huella,
                  key_usuario: this.props.key_usuario,
                  key_sucursal: itm.key,
                  codigo: obj
                }
              })
            }
          })
        }}>
          <SHr height={16} />
          <SView style={{ height: 100, width: 100 }} center>
            <SIcon name={"Fp"} fill={huella ? STheme.color.text : STheme.color.text + "22"} />
          </SView>
          <SHr />
          <SText center fontSize={12} bold >Huella digital <SText bold color={"yellow"}>{obj == 1 ? "izquierdo" : "derecho"}</SText>
          </SText>
          <SHr />
          <SView backgroundColor='red' style={{ width: "100%", borderRadius: 6, height: 32 }} center onPress={() => {
            SPopup.confirm({
              title: "Eliminar", message: "¿Esta seguro de eliminar huella" + (obj == 1 ? "izquierdo" : "derecho") + " ?", onPress: () => {
                let data = { ...huella, estado: 0, fecha_edit: new SDate().toString() }

                // console.log("holaaa elimino ")
                this.discor("Huella eliminada");
                SSocket.sendPromise({
                  service: "zkteco",
                  component: "usuario_huella",
                  type: "editar",
                  data: data
                })
                  .then((resp) => {
                    this.setState({ loading: false, data: resp.data });
                    this.props.dispatch(resp);
                  })
                  .catch((e) => {
                    this.setState({ loading: false, error: e });
                  });
              }
            })
          }} >
            <SText center fontSize={12} color={"white"}>Eliminar huella digital</SText>
          </SView>
          <SHr height={16} />
        </SView >
        <SView width={8} />
      </>
    })
  }

  render() {
    if (!this.props.key_usuario) return null;
    if (this.state.solicitud) {
      SNavigation.navigate("lector_huella", { soli: JSON.stringify(this.state.solicitud) });
      this.state.solicitud = null;
      return <SText>Exito</SText>;
    }
    return (
      <SView col={"xs-12"} center row>
        {this.getLista()}
      </SView>
    );
  }
}
const initStates = (state) => {
  return { state }
};
export default connect(initStates)(HuellasDeUsuario);